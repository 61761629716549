body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:  rgb(0, 54, 19);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 6vh;
  font-size: 1.5vh;
  border-bottom: 2px solid goldenrod;
}

.link {
  padding-left: 2vh;
  padding-right: 2vh;
  color: rgb(255, 211, 99);
  font-weight: 600;
}