body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
}

.header{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    visibility: visible;
    align-items: center;
    max-width: none;
}
.header-image {
    width: 100%;
    object-fit: cover;
    opacity: 0.65;
}
.header-logo{
    height: 35vh;
}
.head-logo{
    display: flex;
    justify-items: center;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
}
.header-content{
    display: flex;
    position: absolute;
    top: 10%;
    flex-direction: column;
    align-items: center;
}

.register-button {
    color: white;
    align-items: center;
    background-color: #171717;
    border-radius: 15px;
    border: 3px solid white;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 3vh;
    font-weight: 700;
    height: 8vh;
    width: 50vw;
    letter-spacing: 2px;
    overflow: visible;
    padding: 2px 24px;
    text-align: center;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    touch-action: manipulation;
    will-change: transform,opacity;
    display: inline-block;
    white-space: nowrap;
    padding: 10px;

}
    
.register-button:hover {
      background: #F6F9FE;
      color: black
}    

.regInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    color: white;
    text-align: center;
    text-shadow: 4px 3px 10px black;
    padding: 5vh;
}

.title{
    font-size: 8vh;
    padding: 0;
    margin: 0;
}
.eventTitle{
    font-size: 6vh;
    padding: 0;
    margin: 0;
}

.description{
    font-size: 5vh;
    padding: 0;
    margin: 0;
}

/*.modal {
    height: 90vh;
    width: 100vh;
    background-image: url('/mfsb-home.jpg');
    background-size: cover;
    background-position: center;
    border: 3px solid rgb(255, 255, 255);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "DM Sans", sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
}
*/
.registrationModal{
    background-color: rgba(255, 255, 255, 0.563);
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 20px;
    position: relative;
}

.Registration-Type{
    font-weight: 700;
    font-size: 30px;
    color: white;
    letter-spacing: 1px;
    text-shadow: 5px 3px 12px rgb(0, 0, 0);
    position: relative;
    top: 4vh;
    text-align: center;
}
.registrationDetails{
    font-size: 18px;
}

.modal-details{
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
}
.modal-detail:hover{
    text-decoration: underline;
    text-underline-offset: 3px;
    color: white;
    letter-spacing: 2px;
}
.modal-detail {
    transition: color 0.3s ease;
}
.close{
    align-self: flex-start;
    margin-left: 30px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 35px;
    padding: 2px;
    color: rgb(0, 0, 0);
}

.close:hover {
    background-color: white;
}

.registration-cards{
    display: flex;
    flex-direction: row;
    margin: 50px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.registration-card{
    height: 60vh;
    width: 50vh;
    margin: 20px;
    box-shadow: 2px 7px 15px -3px black;
    border-radius: 10px;
    border: 5px inset rgb(255, 196, 0);
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    font-size: 1.5vh;
    background-color: rgb(0, 110, 53);
    color:white;
}
.card-header{
    text-align: center;
    color: gold;
    text-decoration:overline;
    text-shadow: 1px 3px 4px black;
    font-size: 3vh;
}
.purchase{
    height: 5vh;
    width: 150px;
    background-color: black;
    color: white;
    position: absolute;         
    bottom: 30px;               
    left: 50%;                  
    transform: translateX(-50%);
    font-weight: 700;
    font-size: 16px;
    
}
.card-details{
    margin: 10px;
}
.cost{
    font-size: 3vh;
    font-weight: 600;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;

}
.registration{
    background-color: rgb(0, 54, 19);
}

@media (max-width: 1400px) {
    .header-image{
        height: 100vh
    }
}

@media (max-width: 1190px) {
    .header-image{
        max-width: none;
        object-fit: cover;
    }
}
@media (min-width: 375px) {
    .title{
        font-size: 5vh;
    }
    .eventTitle{
        font-size: 4vh;
    }
    .description{
        font-size: 3.5vh;
    }
}
@media(min-width: 750px) {
    .register-button{
        width: 40vh;
    }
}