.who{
    display: flex;
    flex-direction: row;
}
.about-caption{
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 2vh;
}
#about-caption-heading{
    align-self: center;
    text-align: center;
    color: gold;
    text-decoration:overline;
    text-shadow: 1px 3px 4px black;
    font-size: 2.5vh;
}
.about-pic{
    height: 30vh;
}
.about-cards{
    display: flex;
    flex-direction: row;
    padding-left: 2vh;
    padding-right: 2vh;
    flex-wrap: wrap;
    margin: 5vh;
    margin-bottom: 15vh;
    justify-content: space-around;
    align-items: center;
}
.about-card{
    margin: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 3vh;
    height: 50vh;
    width: 45vh;
}

.welcome{
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    color: white;
    text-shadow: 4px 3px 10px black;
    margin: 6vh;
    font-size: 3.6vh;
}
.emphasis{
    color: gold;
}
.footnote{
    margin-top: 2vh;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40vh;
    padding-right: 40vh;
    
}
.footnote-header{
    align-self: center;
    text-align: center;
    color: goldenrod;
    text-decoration:overline;
    text-shadow: 1px 3px 3px black;
    padding-top: 5vh;
    
}
.commitee{
    display: flex;
    flex-direction: column;
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    padding: .5vh;
    letter-spacing: .2vh;
    color: bisque;
}